<template>
  <b-card-code v-if="$checkPermission($options.name, 'view')"  id="streets" no-body>

    <div class="page-header">
      <b-row>
        <b-col class="filtersContainer" md="12">

          <div class="filters">

            <div v-if="$checkPermission($options.name, 'insert')" class="filter-column">
              <b-button @click="add" v-b-modal.modal-form variant="primary">Ավելացնել</b-button>
            </div>

            <div class="filter-column">
              <v-select
                  @input="filterRegions"
                  placeholder="Մարզ"
                  id="vue-select-region-filter"
                  :reduce="prefix => prefix.id"
                  v-model="filters.region"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="regions"
                  class="selectorFilter"
              />
            </div>

            <div class="filter-column">
              <v-select
                  v-if="filters.region !== null"
                  @input="filterStreets"
                  placeholder="Համայնք"
                  id="vue-select-county-filter"
                  :reduce="prefix => prefix.id"
                  v-model="filters.county"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="counties"
                  class="selectorFilter"
              />
            </div>

            <div class="filter-column">
              <b-form-input
                  @change="filterStreets"
                  v-model="filters.name"
                  id="owner_name"
                  type="text"
                  placeholder="Փնտրել..."
                  class="w-full opinionFilter"
                  clearable
              />
            </div>

          </div>

        </b-col>
      </b-row>
    </div>

    <b-table
        striped
        hover
        responsive
        class="position-relative"
        :items="items"
        :fields="fields"
    >
      <template #cell(createdBy)="data">
        {{data.value ? data.value.name : ''}}
      </template>
      <template #cell(region)="data">
        {{ regionName(data.item.county) }}
      </template>
      <template #cell(county)="data">
        {{ countyName(data.value) }}
      </template>
      <template #cell(actions)="data">
        <fa-icon v-if="$checkPermission($options.name, 'update')"  @click="changeStatus(data.item)" icon="power-off"
                 :class="'table-action-icon item-status status-' + data.item.status"/>
        <fa-icon v-if="$checkPermission($options.name, 'update')"  @click="edit(data.item)" v-b-modal.modal-form icon="edit" class="table-action-icon accent"/>
        <fa-icon v-if="$checkPermission($options.name, 'delete')"  @click="deleteItem(data.item)" icon="trash-alt" class="table-action-icon danger"/>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

      <!-- page length -->
      <b-form-group
          :label="'Գտնվել է ' + pagination.total + ' տող'"
          label-cols="3"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
      >
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
            @input="getStreets"
            v-model="pagination.current_page"
            :total-rows="pagination.total"
            :per-page="pagination.count"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
        >
          <template #prev-text>
            <feather-icon
                icon="ChevronLeftIcon"
                size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
                icon="ChevronRightIcon"
                size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>

    <!-- modal login-->
    <b-modal
        :no-close-on-backdrop="true"
        id="modal-form"
        cancel-variant="outline-secondary"
        :ok-title="modal_title"
        cancel-title="Կասեցնել"
        centered
        size="sm"
        :title="modal_title"
        @ok="confirmForm"
        :ok-disabled="validateForm"
    >
      <b-form>
        <b-form-group>
          <v-select
              @input="changeFormRegion"
              placeholder="Մարզ"
              id="vue-select-region"
              :reduce="prefix => prefix.id"
              v-model="form.region"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="regions"
          />
        </b-form-group>
        <b-form-group>
          <v-select
              placeholder="Համայնք"
              id="vue-select-county"
              :reduce="prefix => prefix.id"
              v-model="form.county"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="counties"
          />
        </b-form-group>
        <b-form-group>
          <b-form-input
              v-model="form.name"
              id="name"
              type="text"
              placeholder="Մարզի անվանում"
          />
        </b-form-group>
      </b-form>
    </b-modal>

  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import locations from '@/store/locations/locations'
import {
  BAvatar,
  BBadge,
  BButton,
  BCardBody,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BRow,
  BTable
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'

export default {
  name: 'settings-streets',
  components: {
    BCardCode,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BForm,
    BRow,
    BCol,
    vSelect
  },
  data() {
    return {
      LOCATIONS_STORE_MODULE_NAME: 'locationsModule',
      perPage: 20,
      pageOptions: [this.perPage],
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        {
          key: 'id',
          label: 'ID',
        },
        {
          key: 'createdBy',
          label: 'Ավելացրել '
        },
        {
          key: 'region',
          label: 'Մարզ',
        },
        {
          key: 'county',
          label: 'Համայնք',
        },
        {
          key: 'name',
          label: 'Փողոց',
        },
        {
          key: 'actions',
          label: 'Կարգավիճակ',
          class: 'text-center width-10-per px-0',
        },
      ],
      addForm: false,
      editForm: false,
      formFields: {
        name: '',
        county: ''
      },
      form: {},
      filters: {
        region: null,
        county: null,
        name: ''
      }
    }
  },
  computed: {

    items() {
      return this.$store.state[this.LOCATIONS_STORE_MODULE_NAME].streets
    },

    pagination() {
      return this.$store.state[this.LOCATIONS_STORE_MODULE_NAME].pagination
    },

    counties() {
      const result = []
      const data = this.$store.state[this.LOCATIONS_STORE_MODULE_NAME].counties
      data.map((item) => {
        let temp = {
          id: item.id,
          label: item.name
        }
        result.push(temp)
      })
      return result
    },

    all_counties () {
      return this.$store.state[this.LOCATIONS_STORE_MODULE_NAME].counties
    },

    regions() {
      const result = []
      const data = this.$store.state[this.LOCATIONS_STORE_MODULE_NAME].regions
      data.map((item) => {
        let temp = {
          id: item.id,
          label: item.name,
        }
        result.push(temp)
      })
      return result
    },

    regionName() {
      return (id) => {
        let name = ''
        const index = this.all_counties.findIndex((i) => i.id === id)
        if(index >= 0) {
          let regionId = this.all_counties[index].region
          const regionIndex = this.regions.findIndex((i) => i.id === regionId)
          if (regionIndex >= 0) {
            name = this.regions[regionIndex].label
          }
        }
        return name
      }
    },

    countyName() {
      return (id) => {
        let name = ''
        const index = this.counties.findIndex((i) => i.id === id)
        if (index >= 0) {
          name = this.counties[index].label
        }
        return name
      }
    },

    totalRows() {
      return this.items.length
    },

    modal_title() {
      return (this.addForm) ? 'Ավելացնել' : 'Խմբագրել'
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
          .filter(f => f.sortable)
          .map(f => ({
            text: f.label,
            value: f.key
          }))
    },
    validateForm () {
      return this.form.name === '' || this.form.county === '' || this.form.county === null
    }
  },
  mounted() {
    // Set the initial number of items

  },
  async created() {
    if (!store.hasModule(this.LOCATIONS_STORE_MODULE_NAME)) store.registerModule(this.LOCATIONS_STORE_MODULE_NAME, locations)
    this.getStreets()
    this.getRegions()
    this.getCounties()
  },
  methods: {

    getRegions() {
      this.$store.dispatch(this.LOCATIONS_STORE_MODULE_NAME + '/getRegions')
    },
    getCounties() {
      this.$store.dispatch(this.LOCATIONS_STORE_MODULE_NAME + '/getCounties')
    },
    getStreets() {
      this.$vs.loading()
      const filterData = {
        current_page: this.pagination.current_page,
        ...this.filters
      }
      this.$store.dispatch(this.LOCATIONS_STORE_MODULE_NAME + '/getStreets', this.serialize(filterData))
          .then(() => {
            this.$vs.loading.close()
          })
          .catch((error) => {
            this.$root.$emit('getError', error)
            this.$vs.loading.close()
          })
    },
    changeFormRegion () {
      this.form.county = null
      this.$store.commit(this.LOCATIONS_STORE_MODULE_NAME + '/FILTER_COUNTIES_BY_REGION_ID', this.form.region)
    },
    filterRegions() {
      this.$store.commit(this.LOCATIONS_STORE_MODULE_NAME + '/FILTER_COUNTIES_BY_REGION_ID', this.filters.region)
      this.filterStreets()
    },
    filterStreets () {
      this.pagination.current_page = 1
      this.getStreets()
    },
    serialize(obj) {
      let str = []
      for (let p in obj) {
        if (obj.hasOwnProperty(p)) {
          if (typeof obj[p] !== 'undefined' && obj[p] !== null && obj[p] !== '') {
            str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
          }
        }
      }
      return str.join('&')
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    add() {
      this.editForm = false
      this.addForm = true
      this.form = this.$store.getters['getForm'](true)(this.formFields)(null)
    },
    edit(item) {
      this.addForm = false
      this.editForm = true
      this.form = this.$store.getters['getForm'](false)(this.formFields)(item)
    },
    deleteItem(item) {
      this.$store.dispatch(this.LOCATIONS_STORE_MODULE_NAME + '/deleteStreet', item.id)
          .catch((error) => {
            this.$root.$emit('getError', error)
          })
    },
    changeStatus(item) {
      item.status = !item.status
      this.$store.dispatch(this.LOCATIONS_STORE_MODULE_NAME + '/editStreet', item)
          .catch((error) => {
            this.$root.$emit('getError', error)
            item.status = !item.status
          })
    },
    confirmForm() {
      if (this.addForm) {
        this.form.status = true
        this.$store.dispatch(this.LOCATIONS_STORE_MODULE_NAME + '/addStreet', this.form)
            .catch((error) => {
              this.$root.$emit('getError', error)
            })
      } else {
        this.$store.dispatch(this.LOCATIONS_STORE_MODULE_NAME + '/editStreet', this.form)
            .catch((error) => {
              console.log(error)
              this.$root.$emit('getError', error)
            })
      }
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#streets {
  .table-responsive {
    max-height: calc(var(--vh, 1vh) * 100 - 16rem);
    min-height: calc(var(--vh, 1vh) * 100 - 16rem);
  }
}
</style>

